<template>
  <div class="rounded avatar">
    <template v-if="$config.public.ENV === 'dev'">
      <div class="rounded-full placeholder"></div>
    </template>

    <template v-else>
      <img
        v-if="profileStore.avatarUrl || src"
        :src="profileStore.avatarUrl || src"
        alt="avatar"
        class="rounded-full"
      />
    </template>
  </div>
</template>

<script setup>
const props = defineProps({ src: String });
const profileStore = useProfileStore();
</script>

<style scoped>
.placeholder {
  background-color: #f3f3f1;
  width: 100%;
  height: 100%;
}
</style>
